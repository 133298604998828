/**
 * Msa Duty Service UI
 *
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum FixedCodeHashesDto {
    Medical = 'MEDICAL',
    University = 'UNIVERSITY',
    OtherDepartment = 'OTHER_DEPARTMENT',
    OtherUniversity = 'OTHER_UNIVERSITY',
    DutyTypeG = 'DUTY_TYPE_G',
    DutyTypeJ = 'DUTY_TYPE_J'
}

