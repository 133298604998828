/**
 * Msa Duty Service UI
 *
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorCode {
    General = 'GENERAL',
    CorruptedDocument = 'CORRUPTED_DOCUMENT',
    DateNotInFuture = 'DATE_NOT_IN_FUTURE',
    DatePrecedence = 'DATE_PRECEDENCE',
    DateNotInDutyRange = 'DATE_NOT_IN_DUTY_RANGE',
    DateEndBeforeStart = 'DATE_END_BEFORE_START',
    DocumentsRequired = 'DOCUMENTS_REQUIRED',
    DocumentTypeNotSupported = 'DOCUMENT_TYPE_NOT_SUPPORTED',
    DutyFinished = 'DUTY_FINISHED',
    DutyHasStarted = 'DUTY_HAS_STARTED',
    DutyMustNotHaveActiveRequest = 'DUTY_MUST_NOT_HAVE_ACTIVE_REQUEST',
    DutyNotInFuture = 'DUTY_NOT_IN_FUTURE',
    DutyReplacementIncomplete = 'DUTY_REPLACEMENT_INCOMPLETE',
    DutyReplacementNotAllowed = 'DUTY_REPLACEMENT_NOT_ALLOWED',
    DutyTooFarInFuture = 'DUTY_TOO_FAR_IN_FUTURE',
    DutyTypeNotAllowed = 'DUTY_TYPE_NOT_ALLOWED',
    ExternalServiceError = 'EXTERNAL_SERVICE_ERROR',
    ExternalServiceTimeout = 'EXTERNAL_SERVICE_TIMEOUT',
    FileSizeTooBig = 'FILE_SIZE_TOO_BIG',
    InvalidNumberOfDates = 'INVALID_NUMBER_OF_DATES',
    LanguageRequired = 'LANGUAGE_REQUIRED',
    LanguageNotAllowed = 'LANGUAGE_NOT_ALLOWED',
    LeaveRequestNotAllowed = 'LEAVE_REQUEST_NOT_ALLOWED',
    MaxDocumentSizeReached = 'MAX_DOCUMENT_SIZE_REACHED',
    MaxNumberOfDocumentsExceeded = 'MAX_NUMBER_OF_DOCUMENTS_EXCEEDED',
    MinimalDuration = 'MINIMAL_DURATION',
    NonValidDutyState = 'NON_VALID_DUTY_STATE',
    NonValidRequestState = 'NON_VALID_REQUEST_STATE',
    NoAgreement = 'NO_AGREEMENT',
    NoDocForMedicalReason = 'NO_DOC_FOR_MEDICAL_REASON',
    PreventedDateNotInFuture = 'PREVENTED_DATE_NOT_IN_FUTURE',
    RequestDetailTypeNotMatch = 'REQUEST_DETAIL_TYPE_NOT_MATCH',
    RequestTypeNotAllowed = 'REQUEST_TYPE_NOT_ALLOWED',
    SameYear = 'SAME_YEAR',
    StorageServiceError = 'STORAGE_SERVICE_ERROR',
    TooFewCharacters = 'TOO_FEW_CHARACTERS',
    TooManyCharacters = 'TOO_MANY_CHARACTERS',
    UnableToSaveDocument = 'UNABLE_TO_SAVE_DOCUMENT',
    UnknownCodeValue = 'UNKNOWN_CODE_VALUE'
}

